<template>
  <div class="flex flex-col h-full w-full">
    <nav class="px-8 pt-3 pb-0 flex items-center gap-x-8">
      <router-link
        v-for="tab in tabs"
        :key="tab.name"
        class="item block cursor-pointer px-4 py-1 text-sm border-transparent gap-x-6"
        :class="{ active: $route.path.startsWith(tab.path) }"
        :to="tab.path"
        @click="$emit('navigation', tab)"
        v-text="tab.label"
      />
    </nav>

    <div class="w-full h-full overflow-auto">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThTabsNav',
  props: {
    tabs: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>
.item {
  border-bottom-width: 3px;
}

.item.active {
  font-weight: bold;
  color: var(--primary-color);
  border-color: var(--primary-color) !important;
  line-height: var(--el-line-height);
}
</style>
