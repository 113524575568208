<template>
  <th-page-wrapper>
    <tabs-nav :tabs="tabs" @navigation="trackNavigation">
      <router-view :key="$route.fullPath" :resources="resources" />
    </tabs-nav>
  </th-page-wrapper>
</template>

<script>
import get from 'just-safe-get'
import { mapGetters } from 'vuex'
import TabsNav from '@/components/tabs-nav'
import { getNavigation } from './navigation'
import { useAppConfigStore } from '@/store/app-config'
import { storeToRefs } from 'pinia'

export default {
  components: {
    TabsNav
  },
  setup() {
    const appConfigStore = useAppConfigStore()
    const { featureConfig } = storeToRefs(appConfigStore)
    return { featureConfig }
  },
  data() {
    return {
      resources: {}
    }
  },
  computed: {
    ...mapGetters({
      clientAccountConfiguration: 'Config/getClientAccountConfiguration'
    }),
    useExternal() {
      return get(
        this.clientAccountConfiguration,
        'settings.vouchers.use_external',
        false
      )
    },
    tabs() {
      const tabs = getNavigation({ useExternal: this.useExternal })
      return tabs
        .filter((item) => {
          return (
            this.$isFeatureEnabled(item.flag) &&
            this.$checkPermissions({ scopes: item.scopes })
          )
        })
        .map((item) => {
          return {
            label: this.$t(item.key),
            key: item.key,
            path: item.path
          }
        })
    }
  },
  async mounted() {
    await this.fetchResources()
  },
  methods: {
    async fetchResources() {
      try {
        const {
          branchesV1 = [],
          branchGroups = []
        } = await this.$resourceFetch('branchesV1', 'branchGroups')
        this.resources = { branches: branchesV1, branchGroups }
      } catch (err) {
        this.$logException(err, {
          trackError: false,
          message: this.$t(
            'pages.settings.users.edit.form.errors.fetch.resources.code_XXX.content'
          )
        })
      }
    },
    trackNavigation(item) {
      this.$ampli.eventWithBaseProps('navigationSecondary', {
        navigation_to: this.$t(item.key, 'en')
      })
    }
  }
}
</script>
